/**
 * @author jacks
 * Created on 2022/03/11
 */
import {reactive, ref, Ref, computed} from 'vue'
import store from '@/store/index'
import Api from '@/http/modules/index'
let {
    // 获取所有公司
    'GET/company': companyApi,
    // 获取所有团队
    'GET/organizations': organizationsApi,
    // 用户下拉列表
    'GET/users/list': usersListApi,
} = Api

class tablePagination {
    // 表格自适应高度
    public MI_height: number = window.innerHeight - 210
    // 起始页
    public page: Ref<number> = ref(1) 
    // 条数
    public size: Ref<number> = ref(30) 
    // 总条数
    public MI_total: Ref<number> = ref(0) 
    // 是否请求中,蒙版
    public MI_identify: Ref<boolean> = ref(false)
    // 表格数据
    public MI_tableData: Ref<any[]> = ref([]) 

    public MI_params: any = reactive({})

    private initFn

    constructor(initFn: (param: any, opt: object) => Promise<any>, MI_params?: object){
        this.initFn = initFn
        // this.MI_params = {}
        this.page.value = 1
        this.size.value = 30
    }

    /**
     * 表格请求函数
     * @param otherParam Object
     * @returns promise 
     */
    // fetchData(otherParam?: object): Promise<any> {
    fetchData = (otherParam?: any, opt = {}, _page?: number): Promise<any> => {
        if(this.MI_identify.value === true) return Promise.reject();
        
        this.MI_identify.value = true
        // this.MI_params = otherParam || {}
        Object.assign(this.MI_params, otherParam || {})
        let { page, size } = this
        // 非翻页搜索，重置页数
        // this.page.value = _page || 1

        return this.initFn({ page: page.value, size: size.value, ...this.MI_params}, opt)
            .then((rep: any) => {
                
                let { rowCount } = rep
                this.MI_total.value = rowCount
                
                this.MI_tableData.value = rep.data || rep
                this.MI_identify.value = false
                // 返回数据
                return rep
            }).catch(() => {
                this.MI_tableData.value = []
                this.MI_identify.value = false
            })
    }
    // 页数改变
    MI_pageChange = ( page: number )  => {
        this.page.value = page
        return this.fetchData(this.MI_params, {} , page)
    }
    // 页数改变
    MI_sizeChange = ( size: number ): Promise<any> => {
        // 判断当前页数在条数下是否存在
        /* if(!(page.value <= 1 || MI_total.value <= 0)){
            // 如果不在第一页，并且数据不为0，向上取整，获取最大页数
            let maxPage = Math.ceil( MI_total.value / size )
            // 改变页数的时候，当前页数大于最大页数
            this.page.value = maxPage < page.value ? maxPage : this.page.value
        } */
        this.page.value = 1
        this.size.value = size
        return this.fetchData(this.MI_params)
    }
    /**
     * 弹窗修改行
     * @param row {Object} 初始值
     * @param targetRow {Array} 配置list
     * @param fg {Boolean} 弹窗显示与隐藏
     * @returns Promise
     */
    MI_modifyForm<T extends object>(row: T, targetRow: Ref<Array<FromConfig>>, fg: Ref<boolean>, submitFunc?: Function): Promise<{}>{
        let rowKeys = Object.keys(row)
        
        targetRow.value.map((_config: any) => {
            if(rowKeys.includes(_config.upKey)){
                _config.value = row[_config.upKey as keyof T]
            }
        })

        fg.value = true

        return new Promise((resolve: (value: object) => void, reject) => {
            if(targetRow.value[targetRow.value.length - 1 ].type == 'CALLBACK'){
                targetRow.value.pop()
            } 
            targetRow.value.push({type: 'CALLBACK', callback: resolve, value: '', upKey: '', submitFunc: submitFunc})
        }).then(<T>(params: T) => {
            if(targetRow.value[targetRow.value.length - 1 ].type === 'CALLBACK'){
                targetRow.value.pop()
                // delete params['noUpKey' as keyof T]
            }
            return params
        })
        
    }
    

}

export const cpyCfig = (obj = {}) => {
    return  {
        type: 'SELECT',
        selectType: 'GROUP',
        label: '公司',
        value: '',
        upKey: 'companyId',
        options: store.getters.sysCompany,
        // selectFunc: companyApi,
        seleProps: { label: 'name', valueName: 'id'},
        props: { placeholder: '请选择公司', clearable: true },
        ...obj
    }
}
export const orgCfig = (obj = {}) => {
    return   {
        type: 'SELECT',
        label: '团队',
        value: '',
        upKey: 'orgId',
        options: [],
        selectFunc: organizationsApi,
        props: {clearable: true, placeholder: '请选择团队'},
        seleProps: { label: 'name', valueName: 'id'},
        ...obj
    }
}
export const userCfig = (obj = {}) => {
    let option: any = ref([])
    if(!(obj as any).options){
        usersListApi().then((_userList: object[]) => {
            _userList.map((u: any) => u.label = `${u.realName}(${u.alias})`)
            option.value = _userList
        })
    }

    return {
        type: 'SELECT',
        label: '员工',
        value: '',
        upKey: 'userId',
        options: option,
        props: { disabled: false, placeholder: '请选择用户' },
        seleProps: {
            label: 'label',
            valueName: 'userId',
        },
        ...obj
    }
}

export const isMobile = computed(() => window.innerWidth <= 768)

export default tablePagination